import { Controller } from "@hotwired/stimulus";
import { Chart } from 'chart.js'

const chartDefaults = {
  maintainAspectRatio: false,
  indexAxis: 'y',
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: true,
      displayColors: false,
      position: 'nearest',
      xAlign: function(context) {
        const chartArea = context.chart.chartArea;
        const x = context.tooltip.x;
        const dataIndex = context.tooltip.dataPoints[0].dataIndex;
        const datasetIndex = context.tooltip.dataPoints[0].datasetIndex;
        const currentPoint = `${datasetIndex}-${dataIndex}`;
        
        // Calculate relative position
        const relativePosition = (x - chartArea.left) / (chartArea.right - chartArea.left);
        if (!relativePosition) { return 'left' }
        
        // If it's a new data point, update the position
        if (!context.chart._lastTooltipPoint || context.chart._lastTooltipPoint !== currentPoint) {
          context.chart._lastTooltipPoint = currentPoint;
          context.chart._lastTooltipPosition = relativePosition <= 0.7 ? 'left' : 'right';
        }
        
        console.log('Relative position:', relativePosition, context);
        return context.chart._lastTooltipPosition;
      },
      ...window.chartDefaults?.plugins?.tooltip
    }
  },
  scales: {
    x: {
      display: false
    },
    y: {
      grid: {
        borderWidth: 0,
        offset: false,
        color: 'rgba(248,245,252,1)',
        drawOnChartArea: true,
        lineWidth: 30,
        drawTicks: false
      }
    }
  }
};

export default class extends Controller {
  static targets = ["ratingsChart", "attendanceChart", "attendanceNumbersChart"]
  static values = { 
    attendancePercentage: Number, 
    campfireAttendance: Number,
    ratings: Object,
    maxRatings: Number,
    attendanceNumbers: Array
  }

  connect() {
    if (this.hasRatingsChartTarget) {
      this.initializeRatingsChart();
    }
    if (this.hasAttendanceChartTarget) {
      this.initializeAttendanceChart();
    }
    if (this.hasAttendanceNumbersChartTarget) {
      this.initializeAttendanceNumbersChart();
    }
  }

  initializeRatingsChart() {
    const ctx = this.ratingsChartTarget.getContext("2d");

    // Parse the ratings data if it's a string
    const ratingsData = typeof this.ratingsValue === 'string' ? JSON.parse(this.ratingsValue) : this.ratingsValue;
    
    // Create data array in reverse order to match original chart
    const data = ['5', '4', '3'].map(rating => ratingsData[rating] || 0);

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['5', '4', '3'],
        datasets: [{
          data: data,
          backgroundColor: [
            'rgba(100,214,131,1)',  // Green
            'rgba(183,234,131,1)',  // Light green  
            'rgba(246,215,87,1)'    // Yellow
          ],
          borderWidth: 0,
          borderRadius: 4
        }]
      },
      options: {
        ...chartDefaults,
        layout: {
          padding: {
            left: -3,
            top: 0,
            right: 0,
            bottom: 0,
          },
        },
        scales: {
          ...chartDefaults.scales,
          x: {
            ...chartDefaults.scales.x,
            max: this.maxRatingsValue
          },
          y: {
            ...chartDefaults.scales.y,
            ticks: {
              font: {
                size: 18
              }
            }
          }
        },
        plugins: {
          ...chartDefaults.plugins,
          tooltip: {
            ...chartDefaults.plugins.tooltip,
            callbacks: {
              title: () => null,
              label: (context) => `${context.raw} reviews`
            }
          }
        }
      }
    });
  }

  initializeAttendanceChart() {
    const ctx = this.attendanceChartTarget.getContext("2d");

    const verticalLinePlugin = {
      id: 'verticalLine',
      afterDatasetsDraw: function(chartInstance) {
        var xScale = chartInstance.scales["x"];
        let yScale = chartInstance.scales["y"];
        var line;
        var style;

        if (chartInstance.options.verticalLine) {
          line = chartInstance.options.verticalLine[0];
  
          style = line.style;

          let xValue
          if (line.x) {
            xValue = xScale.getPixelForValue(line.x);
          } else {
            xValue = 0;
          }
  
          ctx.lineWidth = 5;
          if (xValue) {
            ctx.beginPath();
            ctx.setLineDash([4, 7])
            ctx.lineCap = "round"
            ctx.moveTo(xValue, 10);
            ctx.lineTo(xValue, yScale.height - 9);
            ctx.strokeStyle = style;
            ctx.stroke();
          }
        }
      }
    };
    
    var data = {
      labels: [''],
      datasets: [{
        backgroundColor: "rgba(100,214,131,1)",
        data: [this.attendancePercentageValue],
        borderWidth: 0,
        borderRadius: 4
      }]
    };

    new Chart(ctx, {
      type: 'bar',
      data: data,
      plugins: [verticalLinePlugin],
      options: {
        ...chartDefaults,
        scales: {
          x: {
            ...chartDefaults.scales.x,
            max: 100
          },
          y: {
            ...chartDefaults.scales.y,
            grid: {
              ...chartDefaults.scales.y.grid,
              lineWidth: 75
            },
            ticks: {
              display: false
            }
          }
        },
        "verticalLine": [{
          "x": this.campfireAttendanceValue,
          "style": "rgba(0,0,0,0.5)",
          "text": this.attendancePercentageValue + "%",
          "fillStyle": "black"
        }]
      }
    });
  }

  initializeAttendanceNumbersChart() {
    const ctx = this.attendanceNumbersChartTarget.getContext("2d");
    const attendanceData = typeof this.attendanceNumbersValue === 'string' ? JSON.parse(this.attendanceNumbersValue) : this.attendanceNumbersValue;

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: [''],
        datasets: attendanceData.map((item, index) => ({
          label: item.name,
          data: [parseFloat(item.data[0][1])],
          backgroundColor: [
            'rgba(246,215,87,1)',   // Yellow
            'rgba(100,214,131,1)',  // Green
            '#30C7C7',             // Teal
            '#1F95D7'              // Blue
          ][index],
          borderWidth: 0,
          borderRadius: 4
        }))
      },
      options: {
        ...chartDefaults,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            display: false,
            max: 100,
            min: 0,
          },
          y: {
            stacked: true,
            grid: {
              borderWidth: 0,
              drawTicks: false,
              offset: false,
              drawOnChartArea: true,
              lineWidth: 75,
              color: 'rgba(248,245,252,1)'
            },
            ticks: {
              display: false
            }
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
            display: true
          },
          tooltip: {
            ...chartDefaults.plugins.tooltip,
            callbacks: {
              label: (context) => `${context.dataset.label}: ${context.raw}%`
            }
          }
        }
      }
    });
  }
}
